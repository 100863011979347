import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMessageHandlers } from "../../../utils/useMessageHandlers";
import { useChangeSearchRequestStatusMutation } from "../../../slices/new-search-request-items/api";
import {
  requestedItemSearchRequestStatus,
  RequestedItemSearchRequestStatus,
} from "../../../types";
import { Chip, MenuItem, TextFieldProps } from "@mui/material";
import { REQUESTED_ITEM_SEARCH_REQUEST_CHIP_STYLES } from "../../../consts";
import { LoadingTextField } from "../../../components/LoadingTextField";
import { GetNewSearchRequestItemsRequestDto } from "../../../slices/new-search-request-items/types";

type SelectSearchRequestStatusProps = Pick<
  TextFieldProps,
  "fullWidth" | "variant" | "size" | "sx"
> & {
  queryParams: GetNewSearchRequestItemsRequestDto;
  requestedItemId: number;
  value: RequestedItemSearchRequestStatus;
};

export const SelectSearchRequestStatus = ({
  queryParams,
  requestedItemId,
  value,
  ...props
}: SelectSearchRequestStatusProps) => {
  const { t } = useTranslation();
  const { handleSuccess, handleError } = useMessageHandlers();

  const [
    changeSearchRequestStatus,
    { isLoading: isSearchRequestStatusChanging },
  ] = useChangeSearchRequestStatusMutation();

  const changeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      changeSearchRequestStatus({
        queryParams,
        requestedItemId,
        searchRequestStatus: event.target
          .value as RequestedItemSearchRequestStatus,
      })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    },
    [
      changeSearchRequestStatus,
      handleError,
      handleSuccess,
      queryParams,
      requestedItemId,
    ]
  );

  return (
    <LoadingTextField
      loading={isSearchRequestStatusChanging}
      select
      fullWidth
      value={value}
      onChange={changeHandler}
      variant="standard"
      size="small"
      {...props}
    >
      {requestedItemSearchRequestStatus.map((status) => (
        <MenuItem key={status} value={status}>
          <Chip
            label={t(`requestedItemSearchRequestStatuses.${status}`, {
              ns: "common",
            })}
            size="small"
            variant="filled"
            sx={{
              backgroundColor:
                REQUESTED_ITEM_SEARCH_REQUEST_CHIP_STYLES[status],

              "&:hover": {
                cursor: "pointer",
              },
            }}
          />
        </MenuItem>
      ))}
    </LoadingTextField>
  );
};
