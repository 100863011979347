import * as yup from "yup";
import { createYupSchema } from "../../../../../utils/validation";

export const commentSchema = createYupSchema((t) =>
  yup.object({
    comment: yup
      .string()
      .max(2000, t("tooLong", { maxLength: 2000 }))
      .required(t("required")),
  })
);
