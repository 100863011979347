import React, { useCallback } from "react";
import { useGetInternalUsersForSelectFieldQuery } from "../../slices/users/api";
import { LoadingTextField, LoadingTextFieldProps } from "../LoadingTextField";
import { displayFullName } from "../../utils/displayFullName";
import { MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";

export type SelectUserProps = Omit<
  LoadingTextFieldProps,
  "select" | "onChange" | "value"
> & {
  onChange?: (value: number | null) => void;
  value: number | null;
};

export const SelectInternalUser = ({
  onChange,
  value,
  loading,
  ...props
}: SelectUserProps) => {
  const { t } = useTranslation("components");

  const { data: users = [], isLoading: areUsersLoading } =
    useGetInternalUsersForSelectFieldQuery();

  const changeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!onChange) {
        return;
      }

      if (event.target.value === "") {
        onChange(null);
        return;
      }

      onChange(parseInt(event.target.value));
    },
    [onChange]
  );

  return (
    <LoadingTextField
      select
      loading={loading || areUsersLoading}
      onChange={changeHandler}
      value={value ?? ""}
      SelectProps={{
        displayEmpty: true,
      }}
      {...props}
    >
      <MenuItem value={""}>{t("unassigned", { ns: "common" })}</MenuItem>
      {users.map((user) => (
        <MenuItem key={user.id} value={user.id}>
          {displayFullName(user)}
        </MenuItem>
      ))}
    </LoadingTextField>
  );
};
