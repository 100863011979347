import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getUrl, prepareHeaders } from "../../utils/api";
import {
  AddUserRequestDto,
  ChangePasswordRequestDto,
  EditUserRequestDto,
  GetInternalUsersForSelectFieldResponseDto,
  GetUsersResponseDto,
} from "./types";
import { User } from "../../entities";

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/users"),
    prepareHeaders,
  }),
  tagTypes: [
    "User",
    "InquiryManager",
    "Principal",
    "DepartmentHead",
    "Teacher",
  ],
  endpoints: (builder) => ({
    getUsers: builder.query<GetUsersResponseDto, void>({
      query: () => "",
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "InquiryManager" as const,
                id,
              })),
              { type: "InquiryManager", id: "list" },
            ]
          : [{ type: "InquiryManager", id: "list" }],
    }),
    getInquiryManagers: builder.query<User[], void>({
      query: () => "/inquiry-managers",
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Principal" as const,
                id,
              })),
              { type: "Principal", id: "list" },
            ]
          : [{ type: "Principal", id: "list" }],
    }),
    getOrderManagers: builder.query<User[], void>({
      query: () => "/order-managers",
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Principal" as const,
                id,
              })),
              { type: "Principal", id: "list" },
            ]
          : [{ type: "Principal", id: "list" }],
    }),
    getPrincipals: builder.query<User[], number>({
      query: (schoolId) => `/principals/${schoolId}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Principal" as const,
                id,
              })),
              { type: "Principal", id: "list" },
            ]
          : [{ type: "Principal", id: "list" }],
    }),
    getDepartmentHeads: builder.query<User[], number>({
      query: (schoolId) => `/departmentheads/${schoolId}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "DepartmentHead" as const,
                id,
              })),
              { type: "DepartmentHead", id: "list" },
            ]
          : [{ type: "DepartmentHead", id: "list" }],
    }),
    getTeachers: builder.query<User[], number>({
      query: (schoolId) => `/teachers/${schoolId}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Teacher" as const,
                id,
              })),
              { type: "Teacher", id: "list" },
            ]
          : [{ type: "Teacher", id: "list" }],
    }),
    getSchoolUsers: builder.query<User[], number>({
      query: (schoolId) => `/schoolUsers/${schoolId}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "User" as const,
                id,
              })),
              { type: "User", id: "list" },
            ]
          : [{ type: "User", id: "list" }],
    }),
    getUser: builder.query<User, number>({
      query: (id) => `/${id}`,
      providesTags: (result, error, id) => [{ type: "User", id }],
    }),
    addUser: builder.mutation<User, AddUserRequestDto>({
      query: (body) => ({ url: "", method: "POST", body }),
      invalidatesTags: [
        { type: "User", id: "list" },
        { type: "Principal", id: "list" },
        { type: "InquiryManager", id: "list" },
      ],
    }),
    editUser: builder.mutation<User, EditUserRequestDto>({
      query: ({ id, ...body }) => ({ url: `/${id}`, method: "PUT", body }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "User", id: "list" },
        { type: "User", id },
        { type: "Principal", id: "list" },
        { type: "Principal", id },
        { type: "InquiryManager", id: "list" },
        { type: "InquiryManager", id },
      ],
    }),
    changePassword: builder.mutation<
      { message: string },
      ChangePasswordRequestDto
    >({
      query: ({ id, ...body }) => ({
        url: `/changepassword/${id}`,
        method: "PATCH",
        body,
      }),
    }),
    changeUserActivity: builder.mutation<User, { id: number; active: boolean }>(
      {
        query: ({ id, ...body }) => ({
          url: `/${id}/changeActivity`,
          method: "PUT",
          body,
        }),
        invalidatesTags: (result, error, { id }) => [
          { type: "User", id: "list" },
          { type: "User", id },
          { type: "Principal", id: "list" },
          { type: "Principal", id },
          { type: "InquiryManager", id: "list" },
          { type: "InquiryManager", id },
        ],
      }
    ),
    deleteUser: builder.mutation<{ message: string }, number>({
      query: (id) => ({ url: `/${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, id) => [
        { type: "User", id: "list" },
        { type: "User", id },
        { type: "Principal", id: "list" },
        { type: "Principal", id },
        { type: "InquiryManager", id: "list" },
        { type: "InquiryManager", id },
      ],
    }),
    getInternalUsersForSelectField: builder.query<
      GetInternalUsersForSelectFieldResponseDto,
      void
    >({
      query: () => `/internal-users-for-select-field`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "User" as const,
                id,
              })),
              { type: "User", id: "list" },
            ]
          : [{ type: "User", id: "list" }],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetInquiryManagersQuery,
  useGetOrderManagersQuery,
  useGetPrincipalsQuery,
  useGetDepartmentHeadsQuery,
  useGetTeachersQuery,
  useGetSchoolUsersQuery,
  useGetUserQuery,
  useAddUserMutation,
  useEditUserMutation,
  useChangePasswordMutation,
  useChangeUserActivityMutation,
  useDeleteUserMutation,
  useGetInternalUsersForSelectFieldQuery,
} = usersApi;
