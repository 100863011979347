import { api } from "../api";
import {
  ChangeAssigneeRequestDto,
  ChangeAssigneeResponseDto,
  ChangeCommentRequestDto,
  ChangeCommentResponseDto,
  ChangeNewSearchRequestItemStatusRequestDto,
  ChangeNewSearchRequestItemStatusResponseDto,
  GetNewSearchRequestItemDetailsRequestDto,
  GetNewSearchRequestItemDetailsResponseDto,
  GetNewSearchRequestItemsRequestDto,
  GetNewSearchRequestItemsResponseDto,
} from "./types";
import { DEFAULT_SORT_MODEL_LAST_ON_TOP } from "../../consts";
import { baseUrl } from "./baseUrl";

export const newSearchRequestItemsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getNewSearchRequestItems: builder.query<
      GetNewSearchRequestItemsResponseDto,
      GetNewSearchRequestItemsRequestDto
    >({
      query: ({ page, pageSize, search, sortModel }) => {
        const urlSearchParams = new URLSearchParams();

        urlSearchParams.append("page", JSON.stringify(page + 1));
        urlSearchParams.append("pageSize", pageSize.toString());

        urlSearchParams.append(
          "sortField",
          sortModel && sortModel.length > 0
            ? sortModel[0].field
            : DEFAULT_SORT_MODEL_LAST_ON_TOP[0].field
        );
        urlSearchParams.append(
          "sort",
          sortModel && sortModel.length > 0
            ? JSON.stringify(sortModel[0].sort)
            : JSON.stringify(DEFAULT_SORT_MODEL_LAST_ON_TOP[0].sort)
        );

        if (search && search.length > 0) {
          urlSearchParams.append("search", search);
        }

        return `/${baseUrl}?${urlSearchParams.toString()}`;
      },
      providesTags: (result) =>
        result
          ? [
              { type: "Item", id: "list" },
              ...result.data.map(({ id }) => ({
                type: "Item" as const,
                id,
              })),
            ]
          : [{ type: "Item", id: "list" }],
    }),
    getNewSearchRequestItemDetails: builder.query<
      GetNewSearchRequestItemDetailsResponseDto,
      GetNewSearchRequestItemDetailsRequestDto
    >({
      query: ({ itemId }) => `/${baseUrl}/${itemId}`,
      providesTags: (_result, _error, { itemId }) => [
        { type: "Item", id: itemId },
      ],
    }),
    changeSearchRequestStatus: builder.mutation<
      ChangeNewSearchRequestItemStatusResponseDto,
      ChangeNewSearchRequestItemStatusRequestDto
    >({
      query: ({ requestedItemId, searchRequestStatus }) => ({
        url: `${baseUrl}/${requestedItemId}/change-search-request-status`,
        method: "PATCH",
        body: {
          searchRequestStatus,
        },
      }),
      onQueryStarted: async (
        { queryParams, requestedItemId, searchRequestStatus },
        { dispatch, queryFulfilled }
      ) => {
        try {
          await queryFulfilled;

          dispatch(
            newSearchRequestItemsApi.util.updateQueryData(
              "getNewSearchRequestItems",
              queryParams,
              (draft) => ({
                ...draft,
                data: draft.data.map((requestedItem) =>
                  requestedItemId === requestedItem.id
                    ? {
                        ...requestedItem,
                        searchRequestStatus,
                      }
                    : requestedItem
                ),
              })
            )
          );

          dispatch(
            newSearchRequestItemsApi.util.updateQueryData(
              "getNewSearchRequestItemDetails",
              { itemId: requestedItemId },
              (draft) => ({
                ...draft,
                searchRequestStatus,
              })
            )
          );
        } catch {
          /* empty */
        }
      },
    }),
    changeAssignee: builder.mutation<
      ChangeAssigneeResponseDto,
      ChangeAssigneeRequestDto
    >({
      query: ({ requestedItemId, assigneeId }) => ({
        url: `${baseUrl}/${requestedItemId}/change-assignee`,
        method: "PATCH",
        body: {
          assigneeId,
        },
      }),
      onQueryStarted: async (
        { queryParams, requestedItemId, assigneeId },
        { dispatch, queryFulfilled }
      ) => {
        try {
          await queryFulfilled;

          dispatch(
            newSearchRequestItemsApi.util.updateQueryData(
              "getNewSearchRequestItems",
              queryParams,
              (draft) => ({
                ...draft,
                data: draft.data.map((requestedItem) =>
                  requestedItemId === requestedItem.id
                    ? {
                        ...requestedItem,
                        assigneeId,
                      }
                    : requestedItem
                ),
              })
            )
          );

          dispatch(
            newSearchRequestItemsApi.util.updateQueryData(
              "getNewSearchRequestItemDetails",
              { itemId: requestedItemId },
              (draft) => ({
                ...draft,
                assigneeId,
              })
            )
          );
        } catch {
          /* empty */
        }
      },
    }),
    changeComment: builder.mutation<
      ChangeCommentResponseDto,
      ChangeCommentRequestDto
    >({
      query: ({ requestedItemId, comment }) => ({
        url: `${baseUrl}/${requestedItemId}/change-comment`,
        method: "PATCH",
        body: {
          comment,
        },
      }),
      onQueryStarted: async (
        { requestedItemId, comment },
        { dispatch, queryFulfilled }
      ) => {
        try {
          await queryFulfilled;

          dispatch(
            newSearchRequestItemsApi.util.updateQueryData(
              "getNewSearchRequestItemDetails",
              { itemId: requestedItemId },
              (draft) => ({
                ...draft,
                comment,
              })
            )
          );
        } catch {
          /* empty */
        }
      },
    }),
  }),
});

export const {
  useGetNewSearchRequestItemsQuery,
  useGetNewSearchRequestItemDetailsQuery,
  useChangeSearchRequestStatusMutation,
  useChangeAssigneeMutation,
  useChangeCommentMutation,
} = newSearchRequestItemsApi;
