import React from "react";
import {
  PaperPage,
  PaperPageError,
  PaperPageSpinner,
} from "../../../components/layout/PaperPage";
import { useTranslation } from "react-i18next";
import { CustomDataGridServerSide } from "../../../components/CustomDataGrid/CustomDataGridServerSide";
import { useTableTools } from "../../../utils/useContentTools";
import { useColumns } from "./useColumns";
import { useGetNewSearchRequestItemsQuery } from "../../../slices/new-search-request-items/api";

type DataTableProps = {
  params: ReturnType<typeof useTableTools>;
};

export const DataTable = ({ params }: DataTableProps) => {
  const { t } = useTranslation("pages");

  const {
    page,
    pageChangeHandler,
    pageSize,
    pageSizeChangeHandler,
    sortModel,
    sortModelChangeHandler,
    search,
    debouncedSearch,
    setSearch,
  } = params;

  const queryParams = {
    page,
    pageSize,
    sortModel,
    search: debouncedSearch,
  };

  const {
    data: items,
    isLoading: areItemsLoading,
    isFetching: areItemsFetching,
    error: itemsLoadingError,
    refetch: refetchItems,
  } = useGetNewSearchRequestItemsQuery(queryParams);

  const columns = useColumns({
    queryParams,
  });

  if (itemsLoadingError) {
    return (
      <PaperPageError
        refetch={refetchItems}
        disabled={areItemsFetching}
        message={t("newSearchRequestItems.refetch")}
      />
    );
  }

  if (areItemsLoading || !items) {
    return <PaperPageSpinner />;
  }

  return (
    <PaperPage>
      <CustomDataGridServerSide
        autoHeight
        columns={columns}
        rows={items.data}
        disableSelectionOnClick
        disableColumnMenu
        rowCount={items.count}
        page={page}
        pageSize={pageSize}
        sortModel={sortModel}
        search={search}
        onPageChange={pageChangeHandler}
        onPageSizeChange={pageSizeChangeHandler}
        onSortModelChange={sortModelChangeHandler}
        setSearch={setSearch}
        loading={areItemsFetching}
      />
    </PaperPage>
  );
};
