import { Box } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useChangeCommentMutation } from "../../../../../slices/new-search-request-items/api";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CommentForm } from "./CommentForm";
import { commentSchema } from "./schema";
import { useYup } from "../../../../../utils/validation";
import { useTranslation } from "react-i18next";
import { ControlledCustomField } from "../../../../../components/controlledFields/ControlledCustomField";
import { LoadingTextField } from "../../../../../components/LoadingTextField";
import { LoadingButton } from "@mui/lab";

type CommentProps = {
  requestedItemId: number;
  comment: string | null;
};

export const Comment = ({ requestedItemId, comment }: CommentProps) => {
  const { t } = useTranslation();
  const { handleSuccess, handleError } = useMessageHandlers();

  const { control, handleSubmit, reset, watch } = useForm<CommentForm>({
    resolver: yupResolver(useYup(commentSchema)),
  });

  useEffect(() => {
    reset({
      comment: comment ?? "",
    });
  }, [comment, reset]);

  const [changeComment, { isLoading }] = useChangeCommentMutation();

  const submitHandler = useCallback(
    (commentForm: CommentForm) => {
      changeComment({
        requestedItemId,
        comment: commentForm.comment,
      })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    },
    [changeComment, handleError, handleSuccess, requestedItemId]
  );

  const areThereChangesToSave = comment !== watch("comment");

  return (
    <Box
      sx={{
        marginTop: "4px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <ControlledCustomField
        control={control}
        name="comment"
        render={(errorMessage, { field: { value, onChange } }) => (
          <LoadingTextField
            value={value}
            onChange={onChange}
            error={!!errorMessage}
            helperText={errorMessage}
            multiline
            rows={5}
            fullWidth
            disabled={isLoading}
          />
        )}
      />
      <LoadingButton
        loading={isLoading}
        onClick={handleSubmit(submitHandler)}
        disabled={!areThereChangesToSave}
        variant="outlined"
      >
        {t("save", { ns: "common" })}
      </LoadingButton>
    </Box>
  );
};
