import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";
import { Link as MaterialLink } from "@mui/material";
import { Link } from "react-router-dom";
import { baseUrl } from "../baseUrl";
import {
  GetNewSearchRequestItemsRequestDto,
  GetNewSearchRequestItemsResponseDto,
} from "../../../slices/new-search-request-items/types";
import { SelectSearchRequestStatus } from "../selectors/SelectSearchRequestStatus";
import { SelectAssignee } from "../selectors/SelectAssignee";

export const useColumns = ({
  queryParams,
}: {
  queryParams: GetNewSearchRequestItemsRequestDto;
}) => {
  const { t } = useTranslation("pages");

  return useMemo<
    GridColDef<GetNewSearchRequestItemsResponseDto["data"][number]>[]
  >(() => {
    return [
      {
        field: "searchRequestStatus",
        headerName: t("newSearchRequestItems.columns.searchRequestStatus"),
        flex: 0.13,
        renderCell: ({ row: { id, searchRequestStatus } }) => (
          <SelectSearchRequestStatus
            queryParams={queryParams}
            requestedItemId={id}
            value={searchRequestStatus}
            size="small"
            variant="standard"
            fullWidth
          />
        ),
      },
      {
        field: "assignee",
        headerName: t("newSearchRequestItems.columns.assignee"),
        flex: 0.13,
        renderCell: ({ row: { id, assigneeId } }) => (
          <SelectAssignee
            queryParams={queryParams}
            requestedItemId={id}
            assigneeId={assigneeId}
            size="small"
            variant="standard"
            fullWidth
            sx={{
              ".MuiSelect-select": {
                fontSize: "0.875rem",
                lineHeight: "1.43",
              },

              ".MuiInputBase-inputAdornedStart": {
                marginLeft: "4px",
              },
            }}
          />
        ),
      },
      {
        field: "supplier",
        headerName: t("newSearchRequestItems.columns.supplier"),
        flex: 0.1,
        renderCell: ({ row: { supplier } }) =>
          supplier ?? t("na", { ns: "common" }),
      },
      {
        field: "supplierProductCode",
        headerName: t("newSearchRequestItems.columns.supplierProductCode"),
        flex: 0.15,
        renderCell: ({ row: { supplierProductCode } }) =>
          supplierProductCode ?? t("na", { ns: "common" }),
      },
      {
        field: "name",
        headerName: t("newSearchRequestItems.columns.name"),
        flex: 0.15,
        renderCell: ({ row: { id, name } }) => (
          <MaterialLink component={Link} to={`${baseUrl}/${id}`}>
            {name}
          </MaterialLink>
        ),
      },
      {
        field: "quantity",
        headerName: t("newSearchRequestItems.columns.quantity"),
        flex: 0.05,
        type: "number",
        valueGetter: ({ row: { quantity } }) => quantity,
      },
      {
        field: "schoolName",
        headerName: t("newSearchRequestItems.columns.school"),
        flex: 0.15,
        valueGetter: ({ row: { schoolName } }) => schoolName,
      },
    ];
  }, [queryParams, t]);
};
