import React, { useCallback } from "react";
import {
  SelectInternalUser,
  SelectUserProps,
} from "../../../components/prefilledSelectors/SelectInternalUser";
import { useChangeAssigneeMutation } from "../../../slices/new-search-request-items/api";
import { useMessageHandlers } from "../../../utils/useMessageHandlers";
import { GetNewSearchRequestItemsRequestDto } from "../../../slices/new-search-request-items/types";

type SelectAssigneeProps = Omit<SelectUserProps, "onChange" | "value"> & {
  queryParams: GetNewSearchRequestItemsRequestDto;
  requestedItemId: number;
  assigneeId: number | null;
};

export const SelectAssignee = ({
  queryParams,
  requestedItemId,
  assigneeId,
  ...props
}: SelectAssigneeProps) => {
  const { handleSuccess, handleError } = useMessageHandlers();

  const [changeAssignee, { isLoading }] = useChangeAssigneeMutation();

  const changeHandler = useCallback(
    (assigneeId: number | null) => {
      changeAssignee({
        queryParams,
        requestedItemId,
        assigneeId,
      })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    },
    [changeAssignee, handleError, handleSuccess, queryParams, requestedItemId]
  );

  return (
    <SelectInternalUser
      onChange={changeHandler}
      value={assigneeId}
      loading={isLoading}
      {...props}
    />
  );
};
