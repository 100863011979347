import React, { forwardRef } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { TextFieldCircular } from "./spinners/TextFieldCircular";

export type LoadingTextFieldProps = TextFieldProps & {
  loading?: boolean;
};

export const LoadingTextField = forwardRef(
  (
    {
      loading,
      disabled,
      InputProps,
      ...props
    }: Omit<LoadingTextFieldProps, "ref">,
    ref: LoadingTextFieldProps["ref"]
  ) => {
    const inputProps: TextFieldProps["InputProps"] = { ...InputProps };

    if (loading) {
      inputProps.startAdornment = <TextFieldCircular />;
    }

    return (
      <TextField
        InputProps={inputProps}
        disabled={disabled || loading}
        ref={ref}
        {...props}
        sx={{
          ...props.sx,

          ".MuiInputBase-inputAdornedStart": {
            marginLeft: "8px",
          },
        }}
      />
    );
  }
);
